import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("notification connect")

    setTimeout(() => {
      this.element.classList.add('transform', 'opacity-0', 'transition', 'duration-1000')
    }, 2000)
    setTimeout(() => {
      this.element.remove()
    }, 3000)
  }

  toggle() {
    this.element.remove()
  }

  disconnect() {
    this.element.remove()
  }
}
