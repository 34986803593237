import { appstorePaths } from '../config/rails-intum-paths'
import axios from 'axios'
// bin/rake js_from_routes:generate
// import { Config } from '@js-from-routes/client'
// import appstoreAppstore from 'api/Appstore/AppstoreApi'
// import { videoClips } from '~/api'
// import a1 from '~/api'

const IntumAppstoreKlass = {

  // inconnectorData: {},
  appstoreIndexPath: appstorePaths.appstore_appstore_index_path,
  getContextAppsPath: appstorePaths.get_context_apps_appstore_appstore_index_path,

  /* renderContent */
  renderContent ({
    kind, code, url = '', element_id = 'intum_appstore_content', appstore_path = '', jwt, // eslint-disable-line
    params = {}
  }) {
    params.appstore_path = appstore_path // eslint-disable-line
    params.jwt = jwt

    console.log('renderContent.....', params)

    let path = ''

    if (kind === 'index') {
      path = `${this.appstoreIndexPath}.js`
    } else if (kind === 'show') {
      path = `${this.appstoreIndexPath}/${code}.js`
    } else {
      path = `${this.appstoreIndexPath}/${code}/${kind}.js`
    }

    const fullUrl = `${url}${path}` // + new URLSearchParams(params)
    console.log('url', url)
    console.log('path', path)
    console.log('fullUrl1', fullUrl)

    axios.get(fullUrl, {
      params: params,
      // headers: { Authorization: `Bearer ${token}` },
    }).then(request => {
      console.log('Install Success:', request)
      var content = document.querySelector("#" + element_id) // eslint-disable-line
      request.data.forEach(function (row) {
        if (row.kind === 'html') {
          content.insertAdjacentHTML("beforeend", row.content)
        } else if (row.kind === 'script') {
          const script = document.createElement('script')
          if (row.src) {
            script.src = row.src
            content.appendChild(script)
          } else if (row.content) {
            script.innerHTML = row.content
            content.appendChild(script)
          } else {
            console.error('zly kind???', row)
          }
        } else if (row.kind === 'link') {
          const tag = document.createElement('link')
          tag.setAttribute('href', row.href)
          tag.setAttribute('rel', row.rel)
          tag.setAttribute('media', row.media)
          content.appendChild(tag)
        }
      })
      // content.insertAdjacentHTML("beforeend", "<%#= j render('index', appstore_apps: @appstore_apps) %>");
    }).catch((error) => {
      console.error('renderContent Error:', error)
    })
      .finally(function () {
        const progress = document.querySelector("#intum_appstore_loading")
        if (progress) progress.style.display = 'none'
        document.querySelector("#" + element_id).style.display = 'block' // eslint-disable-line
      })
  }, // renderContent

  /* injectApps */
  injectApps (params = {}) {
    // url = '', context = '', jwt = '', appstorePath = '', returnTo = '', env = {}

    const url = params.url
    delete params.url

    const fullUrl = `${url}${this.getContextAppsPath}.json` // + new URLSearchParams(params)
    console.log('fullUrl2', fullUrl)

    return new Promise(resolve => {
      axios.post(fullUrl, params, {
        // headers: { Authorization: `Bearer ${token}` },
      }).then(request => {
        console.log('findAppsByContext Success:', request)
        resolve(['ok', request.data])
      }).catch((error) => {
        console.error('findAppsByContext Error:', error)
        resolve(['error', error])
      })
      // .finally(function () {
      //   console.error('findAppsByContext finally...')
      //   resolve(['finally', null])
      // })
    })
  } // injectApps

} // IntumAppStore

export default IntumAppstoreKlass
