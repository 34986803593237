import { Controller } from "@hotwired/stimulus"
import { createPopper } from '@popperjs/core'
import { useClickOutside, useTransition } from "stimulus-use"
import { getBackgroundColorInHex, getContrastColorForTagText } from "../helpers"

// Connects to data-controller="tag-picker"
export default class extends Controller {
  static targets = ["mainButton", "mainContainer", "singleTag", "tagTemplate"]

  existingTags = new Set()

  connect() {
    console.log('tagPicker')
    this.mainContainerTarget.classList.add("hidden")

    this.bindActions()
    this.findEmptyElement()

    this.closed = true
    this.singleTagTargets.forEach(tag => {
      this.existingTags.add(tag.dataset.value)
    })

    useTransition(this, { element: this.mainContainerTarget })
    useClickOutside(this, { element: this.element })
  }

  // przypisujemy akcje do wszystkich elementow
  bindActions() {
    this.mainButtonTarget.dataset.action = "click -> tag-picker#toggleTagPicker"
    this.mainContainerTarget.dataset.action = "click -> tag-picker#clickOutsideClose"
    this.singleTagTargets.forEach(element => { element.querySelector("[data-remove]").dataset.action = "click->tag-picker#removeTag" })
  }

  connectMultiselectSearchController() {
    this.multiselectSearchController = this.application.getControllerForElementAndIdentifier(
      this.element.querySelector("[data-controller='multiselect-search']"),
      "multiselect-search"
    )

    this.multiselectSearchInput = this.element.querySelector(`[data-multiselect-search-target="userInput"]`)
    this.multiselectSearchInput.dataset.action += " change -> tag-picker#fillTagText"
    console.log("multiselectSearchInput", this.multiselectSearchInput)
    console.log(`multiselectSearchController`, this.multiselectSearchController)

    this.multiselectSearchController.element.addEventListener("select", (e) => {
      this.fillTagText(e)
      this.closeTagPickerAndApplyChanges()
    })
  }

  toggleTagPicker() {
    if (!this.multiselectSearchController) {
      this.connectMultiselectSearchController()
    }

    if (this.mainContainerTarget.classList.contains("hidden")) {
      this.createEmptyTagPlaceholder()
      this.openTagPicker()
      this.multiselectSearchInput.focus()
    } else {
      this.closeTagPickerAndApplyChanges()
    }
  }

  fillTagColor(color = '#d3d3d3') {
    console.log(`CONTRAST for ${color}`, getContrastColorForTagText(color))
    this.emptyElement.style.backgroundColor = color
    const textColor = getContrastColorForTagText(color)
    this.emptyElement.querySelector('a').style.color = textColor
    this.emptyElement.querySelector('div').style.color = textColor
  }

  openTagPicker() {
    this.closed = false
    this.enter()
    if (!this.tagPopper) {
      this.tagPopper = createPopper(this.mainButtonTarget, this.mainContainerTarget)
    }
  }

  closeTagPickerAndApplyChanges() {
    if (this.closed) return
    this.closed = true
    this.leave()
    if ((this.emptyElement.dataset.value === "") || (this.existingTags.has(this.emptyElement.dataset.value))) {
      this.emptyElement.remove()
      this.multiselectSearchController.clear()
      return
    }
    const tagName = this.emptyElement.dataset.value
    const tagColor = getBackgroundColorInHex(this.emptyElement)
    this.callFindOrCreateTag(tagName, tagColor)
    this.multiselectSearchController.clear()
  }

  fixTagColor(color) {
    console.log("old color", this.emptyElement.style.backgroundColor)
    console.log("new color", color)

    this.emptyElement.style.backgroundColor = color
    const textColor = getContrastColorForTagText(color)
    this.emptyElement.querySelector('a').style.color = textColor
    this.emptyElement.querySelector('div').style.color = textColor

    // // popup
    // fetch(this.element.dataset.notifyUrl)
    //   .then(response => response.text())
    //   .then(notification => {
    //     const notificationFlash = document.querySelector("#notification_flash")
    //     notificationFlash.innerHTML = notification
    //   })
    //   .catch(error => {
    //     console.error('Error:', error)
    //   })
  }

  callFindOrCreateTag(tag) {
    fetch(this.element.dataset.submitUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify({
        task_id: this.element.dataset.objectId,
        tag: tag,
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        console.log(this.emptyElement)

        this.fillTagData(this.emptyElement, data.id)
        this.fixTagColor(data.color)

        this.emptyElement.dataset.created = true
        this.existingTags.add(this.emptyElement.dataset.value)
      })
  }

  fillTagData(tag, id) {
    // tag musi zostac najpierw utworzony na backendzie bo musi miec ID
    // to jest pierwszy element 'a' ktory odpowiada za przekierowanie do listy
    const link = tag.querySelector('a')

    link.href = this.element.dataset.modelUrl + `?tag_ids=${id}`
    tag.id = `tag_${id}`
  }

  createEmptyTagPlaceholder() {
    this.emptyElement = this.findEmptyElement()
    if (!this.emptyElement) {
      const template = this.tagTemplateTarget.content.cloneNode(true)
      this.element.appendChild(template)
    }
    this.emptyElement = this.findEmptyElement()
    this.emptyElement.querySelector("[data-remove]").dataset.action = "click->tag-picker#removeTag"
    this.fillTagColor()
  }

  removeTag(e) {
    const container = e.target.closest('span')
    const data = {
      task_id: this.element.dataset.objectId,
      tag_id: container.id.split('tag_')[1],
    }

    fetch(this.element.dataset.removeUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify(data)
    })
      .then(data => {
        this.existingTags.delete(container.dataset.value)
        container.remove()
      })
  }

  fillTagText(e) {
    const selected = this.multiselectSearchController.getInputTargetsValues()
    const value = selected.length === 0 ? e.target.value.toLowerCase() : selected[0].toLowerCase()

    const target = this.emptyElement.querySelector('a')
    target.innerText = value
    this.emptyElement.dataset.value = value
  }

  findEmptyElement() {
    const element = this.singleTagTargets.find(element => element.dataset.value === "")
    this.emptyElement = element
    return element
  }

  clickOutside(e) {
    // e.preventDefault()
    this.closeTagPickerAndApplyChanges()
  }

  disconnect() {
    this.selectObserver.disconnect()
  }
}
