// import { Controller } from "@hotwired/stimulus"
// import { useClickOutside, useIntersection, useTransition } from 'stimulus-use'
import { useTransition } from 'stimulus-use'
import Rails from "@rails/ujs"
import DropdownController from "./dropdown_controller"
const debounce = require('lodash/debounce')
// import { IntersectionController } from 'stimulus-use'
// import { useTransition } from 'stimulus-use/dist/use-transition'

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends DropdownController {
  static targets = ["menu", "input", "list", "searchWait"]

  eventCreated = false

  connect() {
    console.log('connect1')
    // useIntersection(this)
    // useClickOutside(this)
    this.menuTarget.classList.add('hidden')
    // this.element.textContent = "Hello World from Stimulus1! Dropdown"

    useTransition(this, { element: this.menuTarget })

    this.updateSearchDebounce = debounce(this.updateSearchDebounce, 1000).bind(this)
  }

  fillDropdown(event) {
    // select_id powinien miec format "new_ID_SELECTA_Z_FORMULARZA", wtedy tym dropdownem mozemy wypelnic wlasciwy select w formularzu
    let selectId = event.params.select
    console.log(event.params.select)
    let chosenId = event.params.id
    // chosenId = !Number.isInteger(chosenId) ? '0' : chosenId
    const headerAvatar = document.getElementById(selectId + '_avatar')
    const headerName = document.getElementById(selectId + '_name')
    const selectedAvatar = document.getElementById(selectId + '_avatar_' + chosenId)
    const selectedName = document.getElementById(selectId + '_name_' + chosenId)

    selectId = selectId.split('new_')[1]
    const selectElement = document.getElementById(selectId)

    if (headerName !== null && selectedName !== null) {
      headerName.innerHTML = selectedName.innerText
    }

    if (headerAvatar) {
      if (selectedAvatar) {
        headerAvatar.innerHTML = selectedAvatar.innerHTML
      } else {
        headerAvatar.innerHTML = ''
      }
    }
    chosenId = chosenId === 0 || chosenId === "none" ? '' : chosenId
    console.log(chosenId)
    console.log(selectId)
    selectElement.value = chosenId

    // użyte po to by input wywołał akcje na onchange jeśli została podana
    selectElement.dispatchEvent(new Event("change"))
    this.toggle()
  }

  updateSearchInfoShow() {
    this.searchWaitTarget.classList.remove('hidden')
    this.searchWaitTarget.classList.add('block')
  }

  updateSearchInfoHide() {
    this.searchWaitTarget.classList.add('hidden')
    this.searchWaitTarget.classList.remove('block')
  }

  updateSearch(event) {
    this.updateSearchInfoShow()
    this.updateSearchDebounce(event)
  }

  updateSearchDebounce(event) {
    console.log('update')
    const input = this.inputTarget.value
    const url = this.inputTarget.dataset.url
    console.log(encodeURIComponent(input))

    event.preventDefault()

    Rails.ajax({
      url: url,
      type: 'get',
      data: "query=" + encodeURIComponent(input),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        return true
      },
      success: (partial) => {
        console.log('update success')
        this.updateSearchInfoHide()
        // this.announceChangedSectionToScreenReader()
      },
      error: (e) => {
        console.log('error updating')
        this.updateSearchInfoHide()
      }
    })
  }
}
