/* intum application js v1.0.2 */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import { appstorePaths } from 'src/config/rails-intum-paths'
import * as ActiveStorage from "@rails/activestorage"
import { Turbo } from "@hotwired/turbo-rails"
import * as Bridge from '../src/turbo_native/bridge'
import IntumAppstoreKlass from '../src/appstore/intum-appstore'
import IntumInsightKlass from "../src/insight/intum-insight"
import handleDarkMode from "../src/dark_mode"
import toggleDeleted from '../src/remove_attachments_handler'
import scrollMainPage from '../src/scroll_main_page'
/* Intum Stimulus 1.0 + 3.0.1 */
import "../controllers"

// import { appstorePaths } from 'src/config/rails-intum-paths'
// import App from 'components/organize/OrganizeApp.vue'
// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import "channels"
// import "./tailwind.css"

// Rails.start()
// Turbolinks.start()

Turbo.session.drive = false
Turbo.setProgressBarDelay(200)
// console.log('turbo aktywne:', Turbo)

require("@rails/ujs").start()
ActiveStorage.start()

window.activeStorage = ActiveStorage
window.toggleDeleted = toggleDeleted
window.IntumAppstore = IntumAppstoreKlass
window.IntumInsight = IntumInsightKlass

const appVariant = document.head.querySelector('meta[name="app_variant"]').content
// console.log("appVariant", appVariant)

if (appVariant === "mobile") { scrollMainPage() }
if (appVariant === "hybrid") { window.TurboNativeBridge = Bridge }

handleDarkMode()

console.log("intum application js v1.0.2")
